<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">{{ $t("SUPPLY_RULES.ADD_SUPPLY_RULE") }}</h3>
            </div>
            <div class="col-4 text-right">
              <base-button
                @click="goBack"
                type="button"
                class="btn btn-sm btn-primary"
              >
                {{ $t("COMMON.RETURN_TO_LIST") }}
              </base-button>
            </div>
          </div>
          <div class="card-body">
            <supply-rule-form
              :loading="loading"
              :supplyRuleData="supplyRule"
              :formErrors="formErrors"
              @supplyRuleSubmitted="handleSubmit"
              @formChanged="() => (alertLeave = true)"
            />
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultProcurement from "./defaultSupplyRule";
import SupplyRuleForm from "./partials/SupplyRuleForm";

export default {
  layout: "DashboardLayout",

  components: {
    SupplyRuleForm,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [alertLeave],

  data() {
    const supplyRule = cloneDeep(defaultProcurement);
    return {
      supplyRule: supplyRule,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    goBack() {
      this.$router.push({ name: "List Supply Rules" });
    },

    viewSupplyRule(supplyRule) {
      this.alertLeave = false;
      this.$router.push({
        name: "View Supply Rule",
        params: { id: supplyRule.id },
      });
    },

    async handleSubmit(supplyRule) {
      this.loading = true;
      const supplyRuleData = cloneDeep(supplyRule);

      try {
        await this.$store.dispatch("supplyRules/add", supplyRuleData);
        this.$notify({
          type: "success",
          message: this.$t("SUPPLY_RULES.SUPPLY_RULE_ADDED"),
        });
        const supplyRule = await this.$store.getters["supplyRules/supplyRule"];
        this.viewSupplyRule(supplyRule);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
    async get() {
      const { id, mode } = this.$route.query;
      if (id && mode === "duplication") {
        this.loading = true;
        try {
          await this.$store.dispatch("supplyRules/get", {
            id,
            hasFallback: true,
          });
          const supplyRule = this.$store.getters["supplyRules/supplyRule"];
          delete supplyRule.id;
          delete supplyRule.warehouse;
          delete supplyRule.created_at;
          delete supplyRule.updated_at;
          this.supplyRule = supplyRule;
          this.loading = false;
        } catch (error) {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
          });
          this.loading = false;
        }
      }
    },
  },
};
</script>
